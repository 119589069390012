<template>
  <v-container class="container">
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>
    <v-card v-if="org">
        <v-card-title class="subtitle">My Challenges</v-card-title>

        <div v-if="ownedEvents">
          <v-card-text class="pb-0">
            <p class="mb-0">Create and manage your own events and challenges! After you have created and published your virtual event others can join the competition.</p>
          </v-card-text>      
          <v-card-text class="pb-0" v-if="ownedEvents.length == 0">
          <i class="mb-0">You haven't created a challenge yet.</i>
          </v-card-text>      
          <EventGrid :routeResolver="resolveEventManagerRoute" :items="ownedEvents" icon="fa-pencil-alt" :minimal="true" class="mx-4 mb-4"></EventGrid>
          <p>
            <v-btn color="primary" :to="{name:'organizationChallengesCreate', params: {id: org.id} }" class="ml-4">Create a new sub challenge</v-btn>
            <br/><br/>
          </p>
        </div>
        <div v-if="joinedEvents">
          <v-card-subtitle>Joined Sub Challenges</v-card-subtitle>
          <v-card-text v-if="joinedEvents.length == 0" class="pb-0">
          <i class="mb-0">You haven't joined a challenge yet.</i>
          </v-card-text>      
          <v-card-text v-else class="pb-0">
          <p class="mb-0">You have joined these challenges within this organization.</p>
          </v-card-text>      
          <EventGrid :routeResolver="resolveEventRoute" :items="joinedEvents" :minimal="true" class="mx-4 mb-4"></EventGrid>
        </div>
        <div v-else>
          <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
import EventUtil from "@/util/eventUtil";
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    EventGrid,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      org: null,
      joinedEvents: null,
      ownedEvents: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.org = (await profileService.getGroup(this.$route.params.id)).data;
        this.ownedEvents = (await eventManagerService.getOwnedEvents(/* userCreated */ true, this.org.id)).data.data;
        this.joinedEvents = (await profileService.getGroupEvents(this.org.id, /* userCreated */ true)).data.data;
      }
    },

    resolveEventRoute(eventId) {
      return {name: 'event', params: { id: eventId}}
    },
    resolveEventManagerRoute(eventId) {
      return {name: 'organizationChallengesView', params: { id: this.org.id, eventId: eventId}}
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: this.org.name, exact: true, to: { name: 'organization', params: {id: this.org.id} } },
        { text: 'My Challenges', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    eventUtil() {
      return new EventUtil(this);
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

